import authHeader from "@/service/authHeader";
import http from "@/plugins/http";

class TahsilatService {
  tahsilatcreate(data) {
    return http.post("/v1/tahsilat", data, { headers: authHeader() });
  }
  tahsilatread() {
    return http.get("/v1/tahsilat", { headers: authHeader() });
  }
  tahsilatupdate(data) {
    return http.put(`/v1/tahsilat/${data.id}`, data, { headers: authHeader() });
  }
  tahsilatdeleted(data) {
    return http.delete(`/v1/tahsilat/${data}`, { headers: authHeader() });
  }
  tahsilatreadone(data) {
    return http.get(`/v1/tahsilat/one`, { headers: authHeader(), params: data});
  }
}

export default new TahsilatService();
