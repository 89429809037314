<template>
  <TahakkukView />
</template>

<script>
import TahakkukView from "@/components/forms/tahakkuk/TahakkukView";

export default {
  name: "MaasTahakkukView",
  components: {
    TahakkukView,
  },
};
</script>