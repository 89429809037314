import { createApp } from "vue";
import App from "./App.vue";
import http from "./plugins/http"
import router from "./router";
import store from "./store";
import moment from "moment";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";

loadFonts();

const app = createApp(App);
app.use(router);
app.use(store);
app.directive("can", {
  mounted(el, binding) {
    if (JSON.parse(localStorage.user).role.permissions.map(t=>t.name).includes(binding.value)) {
      el.style.display = "show";
    } else {
      el.style.display = "none";
    }
  },
});
app.config.globalProperties.$axios = http;

app.config.globalProperties.$filters= {
  dateymd(value) {
    if (!value ) return ""
    value = moment(value).format("YYYY-MM-DD")
    return value;
  },
  datedmy(value) {
    if (!value ) return ""
    value = moment(value).format("DD/MM/YYYY")
    return value;
  }
}

app.config.globalProperties.$moment = moment
app.use(vuetify);
app.mount("#app");
