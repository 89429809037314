import PersonelService from "@/service/PersonelService";

export const personel = {
  namespaced: true,
  state: {
    personeldata:[],
    personelselect:{
      birim:["OFİS","KUZEY","GÜNEY","İZALA","KİRA"],
      bolum:["DENETÇİ","KE","ORTAK","MÜDÜR","HAKEDİŞ","ŞANTİYE","TAKİP","ARŞİV","SEKRETER","YEMEKÇİ","HARİTA","ZEMİN","YOK"],
      unvan:["MİMAR","İNŞAAT MÜH","MAKİNA MÜH","ELEKTRİK MÜH","JEOLOJİ MÜH","JEOFİZİK MÜH","HARİTA MÜH","LİSE","YOK"],
      sgkdurum:["EMEKLİ DEĞİL","EMEKLİ","BİLİNMİYOR"],
      durum:["ÇALIŞIYOR","İSTİFA"],
      tipi:["PEŞİN","AY SONU"]
    },
    personelrules:{
      required: value => !!value || "Gerekli",
      isimcountermin: value => value.length >= 2 || `En az 2 karakter`,
      soyisimcountermin: value => value.length >= 2 || `En az 2 karakter`,
      maasnumber: value => (value =="" || Number.isInteger(value)) || "Lütfen sayı giriniz",
      ibancounter: value => (value == "" || value.length == 24) || `Lütfen 24 haneli giriniz`,
      telcounter: value => (value == "" || value.length == 10) || `Lütfen başında sıfır olmadan 10 haneli giriniz.`,
    }
  },
  getters: {
    getPersoneldata: state => state.personeldata,
    getPersonelselect: state => state.personelselect,
    getPersonelrules: state => state.personelrules,
    getPersonellist: state => state.personeldata.map(v=>({value:v.id, title:`${v.isim} ${v.soyisim}`}))
  },
  actions: {
    personelcreate({ commit }, datas) {
      PersonelService.personelcreate(datas).then(res => {
        commit("set_personeldatapush", res.data);
      }).catch(e => console.log(e.response.data)); 
    },
    personelread({ commit, dispatch }) {
      PersonelService.personelread().then(res => {
        commit("set_personeldataread", res.data);
        dispatch("personeldetail/personeldetailread", {}, {root:true});
      }).catch((e) => console.log(e.response.data));
    },
    personelupdate({ dispatch }, datas) {
      PersonelService.personelupdate(datas).then(() => {
        dispatch("personelread");
      }).catch(e => console.log(e.response.data)); 
    },
    personeldeleted({ dispatch }, datas) {
      PersonelService.personeldeleted(datas).then(() => {
        dispatch("personelread");
      }).catch(e => console.log(e.response.data)); 
    },
    personelreadone(context, datas) {
      return PersonelService.personelreadone(datas).then((res) => res.data).catch(e => console.log(e.response.data));
    }
  },
  mutations: {
    set_personeldataread : (state, data) => state.personeldata = data,
    set_personeldatapush : (state, data) => state.personeldata.push(data),
  }
};