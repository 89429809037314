import authHeader from "@/service/authHeader";
import http from "@/plugins/http";

class TahakkukService {
  tahakkukcreate(data) {
    return http.post("/v1/tahakkuk", data, { headers: authHeader() });
  }
  tahakkukread() {
    return http.get("/v1/tahakkuk", { headers: authHeader() });
  }
  tahakkukupdate(data) {
    return http.put(`/v1/tahakkuk/${data.id}`, data, { headers: authHeader() });
  }
  tahakkukdeleted(data) {
    return http.delete(`/v1/tahakkuk/${data}`, { headers: authHeader() });
  }
  tahakkukreadone(data) {
    return http.get(`/v1/tahakkuk/one`, { headers: authHeader(), params: data});
  }
}

export default new TahakkukService();
