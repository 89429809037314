export const permissiondirective = {
  namespaced: true,
  state: {
    permissiondirectivedata:{
        usercreate:"usercreate",
        userread:"userread",
    },
  },
  getters: {
    getPermissiondirectivedata: state => state.permissiondirectivedata,
  },
  actions: {},
  mutations: {}
};