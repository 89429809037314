<template>
  <v-row justify="center">
    <v-dialog v-model="show" :fullscreen="$vuetify.display.name=='xs'" scrollable :retain-focus="false">
        <v-form ref="form" >
            <v-card :width="width" max-height="80vh">
                <v-card-title>
                    <h2>{{ personelprop.isim + " "+ personelprop.soyisim }} BİLGİ</h2>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container fluid class="pa-0">
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-toolbar density="compact">
                              <v-toolbar-title>
                                TOPLAM BAKİYE: <span class="bg-primary pl-3">{{ getPersoneldetaildataaciklama(personelprop.id)[0].tahakkuk-getPersoneldetaildataaciklama(personelprop.id)[0].tahsilat }} TL</span>
                              </v-toolbar-title>
                            </v-toolbar>
                          </v-col>
                        </v-row>
                        <v-divider class="my-4 d-none d-sm-flex"></v-divider>
                        <v-row no-gutters>
                          <v-col cols="12" md="6" class="pa-1">
                            <v-toolbar density="compact">
                              <v-btn size="small" @click="TahakkukCreatedialog=true">+</v-btn>
                              <v-toolbar-title>TAHAKKUK LİSTESİ <span class="bg-primary pl-3">{{ getPersoneldetaildataaciklama(personelprop.id)[0].tahakkuk }} TL</span></v-toolbar-title>
                            </v-toolbar>
                            <v-table fixed-header density="compact">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th>tutar</th>
                                  <th>ay/yil</th>
                                  <th>cinsi</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="tahakkuk in tahakkukdetails(personelprop.id)" :key="tahakkuk.id">
                                  <td class="pa-1"><v-btn @click="updateth(tahakkuk)" size="small"><v-icon size="small" icon="mdi-account-edit"></v-icon></v-btn></td>
                                  <td class="pa-1"><v-btn @click="deletedth(tahakkuk)" size="small"><v-icon size="small" icon="mdi-delete"></v-icon></v-btn></td>
                                  <td class="pa-1">{{ tahakkuk.tutar }}</td>
                                  <td class="pa-1">{{ tahakkuk.ay }}/{{ tahakkuk.yil }}</td>
                                  <td class="pa-1">{{ tahakkuk.cinsi }}</td>
                                </tr>
                              </tbody>
                            </v-table>
                          </v-col>
                          <v-col cols="12" md="6" class="pa-1">
                            <v-toolbar density="compact">
                              <v-btn size="small" @click="TahsilatCreatedialog = true" >+</v-btn>
                              <v-toolbar-title>TAHSİLAT LİSTESİ <span class="bg-primary pl-3">{{ getPersoneldetaildataaciklama(personelprop.id)[0].tahsilat }} TL</span></v-toolbar-title>
                            </v-toolbar>
                            <v-table fixed-header density="compact">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th>tutar</th>
                                  <th>tarih</th>
                                  <th>odemesekli</th>
                                  <th>not</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="tahsilat in tahsilatdetails(personelprop.id)" :key="tahsilat.id">
                                  <td class="pa-1"><v-btn @click="updatets(tahsilat)" size="small"><v-icon size="small" icon="mdi-account-edit"></v-icon></v-btn></td>
                                  <td class="pa-1"><v-btn @click="deletedts(tahsilat)" size="small"><v-icon size="small" icon="mdi-delete"></v-icon></v-btn></td>
                                  <td class="pa-1">{{ tahsilat.tutar }}</td>
                                  <td class="pa-1">{{ $filters.datedmy(tahsilat.tarih) }}</td>
                                  <td class="pa-1">{{ tahsilat.odemesekli }}</td>
                                  <td class="pa-1">{{ tahsilat.not }}</td>
                                </tr>
                              </tbody>
                            </v-table>
                          </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary">KAYDET</v-btn>
                    <v-btn @click="show = false">ÇIKIŞ</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</v-row>

<TahakkukCreate  :personelIdprop="personelprop.id" :visiblethcr="TahakkukCreatedialog" @close="TahakkukCreatedialog=false" />
<TahakkukUpdate  :tahakkukpropid="tahakkukdata?.id" :visiblethup="TahakkukUptadedialog" @close="TahakkukUptadedialog=false" />
<TahakkukDeleted :tahakkukprop="tahakkukdata" :visiblethde="TahakkukDeletedialog" @close="TahakkukDeletedialog=false" />

<TahsilatCreate  :personelIdprop="personelprop.id" :visibletscr="TahsilatCreatedialog" @close="TahsilatCreatedialog=false" />
<TahsilatUpdate  :tahsilatpropid="tahsilatdata?.id" :visibletsup="TahsilatUptadedialog" @close="TahsilatUptadedialog=false" />
<TahsilatDeleted :tahsilatprop="tahsilatdata" :visibletsde="TahsilatDeletedialog" @close="TahsilatDeletedialog=false" />
</template>

<script>
import { mapGetters} from 'vuex';
import TahakkukCreate from "@/components/forms/tahakkuk/TahakkukCreate";
import TahakkukUpdate from "@/components/forms/tahakkuk/TahakkukUpdate";
import TahakkukDeleted from "@/components/forms/tahakkuk/TahakkukDeleted";
import TahsilatCreate from "@/components/forms/tahsilat/TahsilatCreate";
import TahsilatUpdate from "@/components/forms/tahsilat/TahsilatUpdate";
import TahsilatDeleted from "@/components/forms/tahsilat/TahsilatDeleted";

export default {
  name: "MaasBorcindexBilgi",
  props: ["visiblea","personelprop"],
  emits: ["close"],
  components: {
    TahakkukCreate,
    TahakkukUpdate,
    TahakkukDeleted,
    TahsilatCreate,
    TahsilatUpdate,
    TahsilatDeleted,
  },
  data() {
    return {
      TahakkukCreatedialog:false,
      TahakkukUptadedialog:false,
      TahakkukDeletedialog:false,
      tahakkukdata:{id:""},
      TahsilatCreatedialog:false,
      TahsilatUptadedialog:false,
      TahsilatDeletedialog:false,
      tahsilatdata:{id:""}
    }
  },
  methods: {
    updateth(tahakkuk) {
      this.tahakkukdata=tahakkuk;
      this.TahakkukUptadedialog=true;
    },
    deletedth(tahakkuk) {
      this.tahakkukdata=tahakkuk;
      this.TahakkukDeletedialog=true;
    },
    updatets(tahsilat) {
      this.tahsilatdata=tahsilat;
      this.TahsilatUptadedialog=true;
    },
    deletedts(tahsilat) {
      this.tahsilatdata=tahsilat;
      this.TahsilatDeletedialog=true;
    }
  },
  computed: {
      show: {
          get () {
              return this.visiblea
          },
          set (value) {
              if(!value) {
                  this.$emit("close")
              }
          },
      },
      width() {
        switch(this.$vuetify.display.name) {
            case "xs":  return 300
            case "sm":  return 600
            case "md":  return 900
            case "lg":  return 900
            case "xl":  return 900
            default:    return 900
        }
      },
      ...mapGetters({
      getPersoneldetaildataaciklama:"personeldetail/getPersoneldetaildataaciklama",
      tahakkukdetails:"tahakkuk/getTahakkukdatadetail",
      tahsilatdetails:"tahsilat/getTahsilatdatadetail",
    }),
  },
};
</script>