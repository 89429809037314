<template>
  <v-row no-gutters>
    <v-col>
      <v-toolbar>
        <v-btn @click="TahsilatCreatedialog = true">EKLE</v-btn>
        <v-toolbar-title>TAHSİLAT LİSTESİ</v-toolbar-title>
      </v-toolbar>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col>
      <TahsilatRead>
        <template v-slot:readrow>
          <tr v-for="tahsilat in tahsilats" :key="tahsilat.id">
            <td><v-btn @click="update(tahsilat)" size="small"><v-icon class="d-flex d-md-none" icon="mdi-account-edit"></v-icon><span class="d-none d-md-flex">Güncelle</span></v-btn></td>
            <td><v-btn @click="deleted(tahsilat)" size="small"><v-icon class="d-flex d-md-none" icon="mdi-delete"></v-icon><span class="d-none d-md-flex">Sil</span></v-btn></td>
            <td>{{ tahsilat.id }}</td>
            <td>{{ tahsilat.tutar }}</td>
            <td>{{ $filters.datedmy(tahsilat.tarih) }}</td>
            <td>{{ tahsilat.odemesekli }}</td>
            <td>{{ tahsilat.not }}</td>
            <td>{{ tahsilat.createdAt }}</td>
            <td>{{ tahsilat.updatedAt }}</td>
            <td>{{ tahsilat.personelId }}</td>
            <td>{{ personellist.filter(v=>v.value==tahsilat.personelId)[0].title }}</td>
          </tr>
        </template>
      </TahsilatRead>
    </v-col>
  </v-row>
  <TahsilatCreate  :visibletscr="TahsilatCreatedialog" @close="TahsilatCreatedialog=false" />
  <TahsilatUpdate  :tahsilatpropid="tahsilatdata?.id" :visibletsup="TahsilatUptadedialog" @close="TahsilatUptadedialog=false" />
  <TahsilatDeleted :tahsilatprop="tahsilatdata" :visibletsde="TahsilatDeletedialog" @close="TahsilatDeletedialog=false" />
</template>

<script>
import { mapGetters} from 'vuex';
import TahsilatCreate from "@/components/forms/tahsilat/TahsilatCreate";
import TahsilatRead from "@/components/forms/tahsilat/TahsilatRead";
import TahsilatUpdate from "@/components/forms/tahsilat/TahsilatUpdate";
import TahsilatDeleted from "@/components/forms/tahsilat/TahsilatDeleted";

export default {
  name: "TahsilatView",
  components: {
    TahsilatCreate,
    TahsilatRead,
    TahsilatUpdate,
    TahsilatDeleted,
  },
  data() {
    return {
      TahsilatCreatedialog:false,
      TahsilatUptadedialog:false,
      TahsilatDeletedialog:false,
      tahsilatdata:{id:""}
    }
  },
  methods: {
    update(tahsilat) {
      this.tahsilatdata=tahsilat;
      this.TahsilatUptadedialog=true;
    },
    deleted(tahsilat) {
      this.tahsilatdata=tahsilat;
      this.TahsilatDeletedialog=true;
    }
  },
  computed: {
    ...mapGetters({
      tahsilats:"tahsilat/getTahsilatdata",
      personellist: "personel/getPersonellist"
    }),
  },
};
</script>