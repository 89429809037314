<template>
<v-row justify="center">
    <v-dialog v-model="show" :fullscreen="$vuetify.display.name=='xs'" scrollable :retain-focus="false">
        <v-form ref="form" >
            <v-card :width="width" max-height="80vh">
                <v-card-title>
                    <h2>TAHSİLAT EKLEME</h2>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container >
                        <v-row >
                            <v-col cols="12" sm="6" md="4"><v-text-field v-model.number="tahsilatekle.tutar"   :class="tahsilatekle.tutar =='' ? 'text-red' : ''"      :rules="[tahsilatrules.required]" type="text" variant="outlined" hide-details="auto" density="compact" label="TUTAR*" required></v-text-field></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field v-model="tahsilatekle.tarih"   :class="tahsilatekle.tarih =='' ? 'text-red' : ''"      :rules="[tahsilatrules.required]" type="date" variant="outlined" hide-details="auto" density="compact" label="TARİH*" required></v-text-field></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select v-model="tahsilatekle.odemesekli"  :class="tahsilatekle.odemesekli =='' ? 'text-red' : ''" :rules="[tahsilatrules.required]" :items="tahsilatselect.odemesekli" hide-details="auto" density="compact" label="ÖDEME ŞEKLİ*" required></v-select></v-col>
                        </v-row>
                        <v-divider class="my-4 d-none d-sm-flex"></v-divider>
                        <v-row>
                            <v-col cols="12" sm="4" md="4"><v-text-field v-model="tahsilatekle.not" type="text" variant="outlined" hide-details="auto" density="compact" label="NOT*" required></v-text-field></v-col>
                            <v-col cols="12" sm="8" md="5"><v-select v-model.number="tahsilatekle.personelId"  :class="tahsilatekle.personelId =='' ? 'text-red' : ''" :rules="[tahsilatrules.required]" :items="getPersonellist" hide-details="auto" density="compact" label="PERSONEL*" required></v-select></v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="submit">KAYDET</v-btn>
                    <v-btn @click="show = false">ÇIKIŞ</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "TahsilatCreate",
  props: ["visibletscr","personelIdprop"],
  data() {
      return {
          tahsilatekle:{
            tutar:"",
            tarih:"",
            odemesekli:"",
            not:"",
            personelId:"",
          }
      }
  },
  methods: {
      ...mapActions({
          tahsilatcreate:"tahsilat/tahsilatcreate"
      }),
      async submit() {
        if(await this.$refs.form.validate().then(r=>r.valid)) {
            this.tahsilatcreate(this.tahsilatekle);
            Object.keys(this.tahsilatekle).forEach(r=>{this.tahsilatekle[r]=""});
            this.show = false;
        }
      },
      updatea() {
        if(this.personelIdprop) {
            this.tahsilatekle.personelId = this.personelIdprop
        }
      },
  },
  computed: {
      show: {
          get () {
              return this.visibletscr
          },
          set (value) {
              if(!value) {
                  this.$emit("close")
              }
          },
      },
      width() {
        switch(this.$vuetify.display.name) {
            case "xs":  return 300
            case "sm":  return 600
            case "md":  return 900
            case "lg":  return 900
            case "xl":  return 900
            default:    return 900
        }
      },
      ...mapGetters({
          tahsilatselect: "tahsilat/getTahsilatselect",
          tahsilatrules: "tahsilat/getTahsilatrules",
          getPersonellist: "personel/getPersonellist"
      })
  },
  watch: {
    visibletscr: function () { if(this.visibletscr==true) this.updatea()},
  }
};
</script>
