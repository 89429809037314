import TahakkukService from "@/service/TahakkukService";

export const tahakkuk = {
    namespaced: true,
    state: {
        tahakkukdata: [],
        tahakkukselect:{
            ay:[1,2,3,4,5,6,7,8,9,10,11,12],
            yil:[2020,2021,2022,2023],
            cinsi:["MAAŞ","CEPTEN HARCAMA","BORÇ ALINDI"],
        },
        tahakkukrules:{
            required: v=> !!v || "Gerekli",
        }
    },
    getters: {
        getTahakkukdata: state=> state.tahakkukdata,
        getTahakkukselect: state=> state.tahakkukselect,
        getTahakkukrules: state=> state.tahakkukrules,
        getTahakkukdatadetail: state => id => state.tahakkukdata.filter(v=>v.personelId == id),
    },
    actions: {
        tahakkukcreate({ commit, dispatch }, datas) {
            TahakkukService.tahakkukcreate(datas).then(res => {
                commit("set_tahakkukdatapush", res.data);
                dispatch("personeldetail/personeldetailread", {}, {root:true});
            }).catch(e => console.log(e.response.data));
        },
        tahakkukread({ commit, dispatch }) {
            TahakkukService.tahakkukread().then(res => {
                commit("set_tahakkukdataread", res.data);
                dispatch("personeldetail/personeldetailread", {}, {root:true});
            }).catch(e => console.log(e.response.data))
        },
        tahakkukupdate({ dispatch }, datas) {
            TahakkukService.tahakkukupdate(datas).then(() => {
                dispatch("tahakkukread");
            }).catch(e => console.log(e.response.data))
        },
        tahakkukdeleted({ dispatch }, datas) {
            TahakkukService.tahakkukdeleted(datas).then(() => {
                dispatch("tahakkukread");
            }).catch(e => console.log(e.response.data))
        },
        tahakkukreadone(context, datas) {
            return TahakkukService.tahakkukreadone(datas).then((res) => res.data).catch(e => console.log(e.response.data));
        }
    },
    mutations: {
        set_tahakkukdataread: (state, data) => state.tahakkukdata = data,
        set_tahakkukdatapush: (state, data) => state.tahakkukdata.push(data),
    },
}