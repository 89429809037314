<template>
  <v-container fluid fill-height>
    <v-row class="pt-10">
      <v-col cols="12" md="4" offset-md="4" align-items="center">
          <v-card class="elevation-12">
            <v-toolbar dark :color="errorloginshow ? 'error' : 'primary'">
              <v-toolbar-title>GİRİŞ</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12"><v-text-field v-model="user.username" @keydown.self="errorloginshow=false" :rules="[rules.usernnamerequired]" autofocus hide-details="auto" type="text" label="KULLANICI ADI" variant="outlined" shaped></v-text-field></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"><v-text-field v-model="user.password" @keydown.self="errorloginshow=false" :rules="[rules.passwordrequired,rules.passwordmincount]" @keyup.enter="handleLogin" hide-details="auto" type="password" autocomplete="off" label="ŞİFRE" variant="outlined"></v-text-field></v-col>
                </v-row>
                <v-row v-if="errorloginshow">
                  <v-col>
                    <v-alert type="error">{{ errorloginmessage }}</v-alert>
                  </v-col>
                </v-row>
              </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :disabled="false" @click="handleLogin">GİRİŞ</v-btn>
            </v-card-actions>
          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      errorloginmessage: "Kullanıcı adı veya şifre yanlış",
      errorloginshow: false,
      rules: {
        usernnamerequired: v => !!v || 'Lütfen kullanıcı adınızı giriniz',
        passwordrequired: v => !!v || 'Lütfen şifrenizi giriniz',
        passwordmincount: v => (v.length >= 6) || "En az 6 karakter giriniz"
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    async handleLogin() {
      if(await this.$refs.form.validate().then(r=>r.valid)) {
        this.$store.dispatch("auth/login", this.user).then(() => { this.$router.push("/")}).catch(()=>{ this.errorlogin() });
      }
    },
    errorlogin() {
      this.user.username = "";
      this.user.password = "";
      this.errorloginshow = true;
    },
  },
};
</script>
