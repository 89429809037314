<template>
  <TahsilatView />
</template>

<script>
import TahsilatView from "@/components/forms/tahsilat/TahsilatView";

export default {
  name: "MaasTahsilatView",
  components: {
    TahsilatView,
  },
};
</script>