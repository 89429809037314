<template>
  <v-container>
    <v-row>
      <v-col>supermoderator sayfası</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SupermoderatorView"
}
</script>