<template>
    <v-dialog v-model="show" :fullscreen="$vuetify.display.name=='xs'" scrollable :retain-focus="false">
        <v-card :width="width">
            <v-card-title color="#26c6da">
                <h4>Tahakkuk Silme işlemi</h4>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            {{ tahakkukprop.tutar }} {{ tahakkukprop.personelId }} isimli kişiyi silmek istiyormusunuz?
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="submit(tahakkukprop.id)">SİL</v-btn>
                <v-btn @click="show = false">ÇIKIŞ</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name:"TahakkukDeleted",
    props:["visiblethde","tahakkukprop"],
    methods: {
        ...mapActions({
            tahakkukdeleted:"tahakkuk/tahakkukdeleted"
        }),
        submit(data) {
            this.tahakkukdeleted(data);
            this.show = false;
        }
    },
    computed: {
      show: {
          get () {return this.visiblethde},
          set (value) {
            if(!value) {this.$emit("close")}
          },
      },
      
      width() {
        switch(this.$vuetify.display.name) {
            case "xs":  return 300
            case "sm":  return 300
            case "md":  return 300
            case "lg":  return 300
            case "xl":  return 300
            case "xxl": return 300
            default:    return 300
        }
      },
  },
}
</script>