<template>
    <v-app-bar density="compact" elevation="12" :position="$vuetify.display.smAndDown==true ? 'bottom' : 'top'">
        <v-app-bar-nav-icon @click="set_rail(!getRail)"></v-app-bar-nav-icon>
        <v-btn :to="'/'" @click="set_sidebar('anasayfa')"><v-icon>mdi-home</v-icon>ANA SAYFA</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="logout" icon><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
    name:"AppHeader",
    methods: {
        ...mapMutations({
            set_rail: "sekmeler/set_rail",
            set_sidebar:"sekmeler/set_sidebar"
        }),
        ...mapActions({
            logout:"auth/logout"
        })
    },
    computed: {
        ...mapGetters({
            getRail:"sekmeler/getRail",
        })
    },
}
</script>