<template>
  <v-row no-gutters>
    <v-col>
      <v-toolbar>
        <v-btn @click="PersonelCreatedialog = true">EKLE</v-btn>
        <v-toolbar-title>PERSONEL LİSTESİ</v-toolbar-title>
      </v-toolbar>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col>
      <PersonelRead>
        <template v-slot:readrow>
          <tr v-for="personel in personels" :key="personel.id">
            <td><v-btn @click="update(personel)" size="small"><v-icon class="d-flex d-md-none" icon="mdi-account-edit"></v-icon><span class="d-none d-md-flex">Güncelle</span></v-btn></td>
            <td><v-btn @click="deleted(personel)" size="small"><v-icon class="d-flex d-md-none" icon="mdi-delete"></v-icon><span class="d-none d-md-flex">Sil</span></v-btn></td>
            <td>{{ personel.id }}</td>
            <td>{{ personel.isim }}</td>
            <td>{{ personel.soyisim }}</td>
            <td>{{ personel.bolum }}</td>
            <td>{{ personel.unvan }}</td>
            <td>{{ personel.birim }}</td>
            <td>{{ personel.maas }}</td>
            <td>{{ personel.sgksure }}</td>
            <td>{{ personel.sgkdurum }}</td>
            <td>{{ personel.durum }}</td>
            <td>{{ personel.iban }}</td>
            <td>{{ personel.telefon }}</td>
            <td>{{ $filters.datedmy(personel.giris) }}</td>
            <td>{{ personel.cikis }}</td>
            <td>{{ personel.tipi }}</td>
            <td>{{ personel.not }}</td>
            <td>{{ personel.createdAt }}</td>
            <td>{{ personel.updatedAt }}</td>
          </tr>
        </template>
      </PersonelRead>
    </v-col>
  </v-row>
  <PersonelCreate  :visible="PersonelCreatedialog" @close="PersonelCreatedialog=false" />
  <PersonelUpdate  :personelpropid="personeldata?.id" :visible="PersonelUptadedialog" @close="PersonelUptadedialog=false" />
  <PersonelDeleted :personelprop="personeldata" :visible="PersonelDeletedialog" @close="PersonelDeletedialog=false" />
</template>

<script>
import { mapGetters} from 'vuex';
import PersonelCreate from "@/components/forms/personel/PersonelCreate";
import PersonelRead from "@/components/forms/personel/PersonelRead";
import PersonelUpdate from "@/components/forms/personel/PersonelUpdate";
import PersonelDeleted from "@/components/forms/personel/PersonelDeleted";

export default {
  name: "PersonelView",
  components: {
    PersonelCreate,
    PersonelRead,
    PersonelUpdate,
    PersonelDeleted,
  },
  data() {
    return {
      PersonelCreatedialog:false,
      PersonelUptadedialog:false,
      PersonelDeletedialog:false,
      personeldata:{id:""}
    }
  },
  methods: {
    update(personel) {
      this.personeldata=personel;
      this.PersonelUptadedialog=true;
    },
    deleted(personel) {
      this.personeldata=personel;
      this.PersonelDeletedialog=true;
    }
  },
  computed: {
    ...mapGetters({
      personels:"personel/getPersoneldata"
    }),
  },
};
</script>