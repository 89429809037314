<template>
  <v-table fixed-header density="compact" height="70vh">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th>id</th>
        <th>isim</th>
        <th>soyisim</th>
        <th>bolum</th>
        <th>unvan</th>
        <th>birim</th>
        <th>maas</th>
        <th>sgksure</th>
        <th>sgkdurum</th>
        <th>durum</th>
        <th>iban</th>
        <th>telefon</th>
        <th>giris</th>
        <th>cikis</th>
        <th>tipi</th>
        <th>not</th>
        <th>createdAt</th>
        <th>updatedAt</th>
      </tr>
    </thead>
    <tbody>
      <slot name="readrow"></slot>
    </tbody>
  </v-table>
</template>

<script>
export default {
  name: "PersonelRead",
};
</script>
