<template>
    <v-container class="pa-0 pa-sm-3" fluid>
      <v-row no-gutters>
        <v-col>
          <v-tabs v-model="tabs" class="mb-3">
            <v-tab fixed value="1">BORÇ</v-tab>
            <v-tab fixed value="2">LİSTE</v-tab>
            <v-tab fixed value="3">PERSONEL</v-tab>
            <v-tab fixed value="4">TAHAKKUK</v-tab>
            <v-tab fixed value="5">TAHSİLAT</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-divider class="mb-3" no-gutters></v-divider>
      <v-row v-if="tabs==1" no-gutters>
        <v-col>
          <MaasBorcView />
        </v-col>
      </v-row>
      <v-row v-if="tabs==2" no-gutters>
        <v-col>
          <MaasListeView />
        </v-col>
      </v-row>
      <v-row v-if="tabs==3" no-gutters>
        <v-col>
          <MaasPersonelView />
        </v-col>
      </v-row>
      <v-row v-if="tabs==4" no-gutters>
        <v-col>
          <MaasTahakkukView />
        </v-col>
      </v-row>
      <v-row v-if="tabs==5" no-gutters>
        <v-col>
          <MaasTahsilatView />
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import MaasBorcView from "@/components/tools/maas/borc/MaasBorcView";
import MaasListeView from "@/components/tools/maas/liste/MaasListeView";
import MaasPersonelView from "@/components/tools/maas/personel/MaasPersonelView";
import MaasTahakkukView from "@/components/tools/maas/tahakkuk/MaasTahakkukView";
import MaasTahsilatView from "@/components/tools/maas/tahsilat/MaasTahsilatView";

export default {
    name: "MaasView",
    components: {
      MaasBorcView,
      MaasListeView,
      MaasPersonelView,
      MaasTahakkukView,
      MaasTahsilatView,
    },
    data() {
        return {
          tabs:"1",
        }
    },
}
</script>