<template>
<v-row justify="center">
    <v-dialog v-model="show" :fullscreen="$vuetify.display.name=='xs'" scrollable :retain-focus="false">
        <v-form ref="form">
            <v-card :width="width" max-height="80vh">
                <v-card-title>
                    <h2>PERSONEL GÜNCELLEME</h2>
                </v-card-title>
                <v-card-text >
                    <v-container>
                        <v-row >
                            <v-col cols="12" sm="6" md="4"><v-text-field v-model="personelekle.isim" :class="personelekle.isim =='' ? 'text-red' : ''"  :rules="[personelrules.required, personelrules.isimcountermin]" type="text" variant="outlined" hide-details="auto" density="compact" label="İSİM*" required></v-text-field></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field v-model="personelekle.soyisim" :class="personelekle.soyisim =='' ? 'text-red' : ''"  :rules="[personelrules.required, personelrules.soyisimcountermin]" type="text" variant="outlined" hide-details="auto" density="compact" label="SOYAD*" required></v-text-field></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field v-model="personelekle.telefon" :rules="[personelrules.telcounter]" type="text" variant="outlined" hide-details="auto" density="compact" label="TELEFON"></v-text-field></v-col>
                        </v-row>
                        <v-divider class="my-4 d-none d-sm-flex"></v-divider>
                        <v-row>
                            <v-col cols="12" sm="4" md="4"><v-text-field v-model.number="personelekle.maas" :rules="[personelrules.maasnumber]" type="text" variant="outlined" hide-details="auto" density="compact" label="MAAŞ"></v-text-field></v-col>
                            <v-col cols="12" sm="8" md="5"><v-text-field v-model="personelekle.iban" :rules="[personelrules.ibancounter]" type="text" variant="outlined" hide-details="auto" density="compact" label="IBAN"></v-text-field></v-col>
                        </v-row>
                        <v-divider class="my-4 d-none d-sm-flex"></v-divider>
                        <v-row>
                            <v-col cols="12" sm="6" md="3"><v-text-field v-model.number="personelekle.sgksure" type="text" variant="outlined" hide-details="auto" density="compact" label="SGK SÜRE"></v-text-field></v-col>
                            <v-col cols="12" sm="6" md="3"><v-select v-model="personelekle.sgkdurum" :items="personelselect.sgkdurum" hide-details="auto" density="compact" label="SGK DURUMU"></v-select></v-col>
                        </v-row>
                        <v-divider class="my-4 d-none d-sm-flex"></v-divider>
                        <v-row>
                            <v-col cols="12" sm="6" md="3"><v-select v-model="personelekle.birim" :class="personelekle.birim =='' ? 'text-red' : ''" :rules="[personelrules.required]" :items="personelselect.birim" hide-details="auto" density="compact" label="BİRİM*"></v-select></v-col>
                            <v-col cols="12" sm="6" md="3"><v-select v-model="personelekle.bolum" :class="personelekle.bolum =='' ? 'text-red' : ''" :rules="[personelrules.required]" :items="personelselect.bolum" hide-details="auto" density="compact" label="BÖLÜM*"></v-select></v-col>
                            <v-col cols="12" sm="6" md="3"><v-select v-model="personelekle.unvan" :class="personelekle.unvan =='' ? 'text-red' : ''" :rules="[personelrules.required]" :items="personelselect.unvan" hide-details="auto" density="compact" label="ÜNVAN*"></v-select></v-col>
                            <v-col cols="12" sm="6" md="3"><v-select v-model="personelekle.tipi"  :class="personelekle.tipi =='' ? 'text-red' : ''"  :rules="[personelrules.required]" :items="personelselect.tipi" hide-details="auto" density="compact" label="TAHAKKUK TİPİ*"></v-select></v-col>
                        </v-row>
                        <v-divider class="my-4 d-none d-sm-flex"></v-divider>
                        <v-row>
                            <v-col cols="12" sm="6" md="3"><v-text-field v-model="personelekle.giris" type="date" variant="outlined" hide-details="auto" density="compact" label="İŞE GİRİŞ TARİHİ"></v-text-field></v-col>
                            <v-col cols="12" sm="6" md="6"><v-text-field v-model="personelekle.not" type="text" variant="outlined" hide-details="auto" density="compact" label="NOTLAR"></v-text-field></v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="submit">KAYDET</v-btn>
                    <v-btn @click="show = false">ÇIKIŞ</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "PersonelCreate",
  props: ["visible","personelpropid"],
  data() {
      return {
          personelekle:{
            isim    : "",
            soyisim : "",
            telefon : "",
            maas    : "",
            iban    : "",
            sgksure : "",
            sgkdurum: "",
            birim   : "",
            bolum   : "",
            unvan   : "",
            tipi    : "",
            giris   : "",
            not     : "",
            durum:"ÇALIŞIYOR"
          }
      }
  },
  methods: {
      ...mapActions({
        personelupdate:"personel/personelupdate",
        personelreadone:"personel/personelreadone"
      }),
      async updatea() {
        console.log(await this.personelreadone({id:this.personelpropid}))
        this.personelekle = await this.personelreadone({id:this.personelpropid})
      },
      async submit() {
        if(await this.$refs.form.validate().then(r=>r.valid)) {
            delete this.personelekle.createdAt
            delete this.personelekle.updatedAt
            this.personelupdate(this.personelekle);
            Object.keys(this.personelekle).forEach(r=>{this.personelekle[r]=""});
            this.personelekle.durum="ÇALIŞIYOR";
            this.show = false;
        }
      },
  },
  computed: {
      show: {
          get () {return this.visible},
          set (value) {
            if(!value) {this.$emit("close")}
            Object.keys(this.personelekle).forEach(r=>{this.personelekle[r]=""});
            this.personelekle.durum="ÇALIŞIYOR";
          },
      },
      
      width() {
        switch(this.$vuetify.display.name) {
            case "xs":  return 300
            case "sm":  return 600
            case "md":  return 900
            case "lg":  return 900
            case "xl":  return 900
            case "xxl": return 900
            default:    return 900
        }
      },
      ...mapGetters({
          personelselect: "personel/getPersonelselect",
          personelrules: "personel/getPersonelrules",
      }),
  },
  watch: {
    visible: function () { if(this.visible==true) this.updatea()},
    'personelekle.giris': function (newValue) {this.personelekle.giris = this.$filters.dateymd(newValue)}
  }
};
</script>
