<template>
    <v-dialog v-model="show" :fullscreen="$vuetify.display.name=='xs'" scrollable :retain-focus="false">
        <v-form ref="form">
            <v-card :width="width">
                <v-card-title>
                    <h2>TAHAKKUK GÜNCELLEME</h2>
                </v-card-title>
                <v-card-text >
                    <v-container>
                        <v-row >
                            <v-col cols="12" sm="6" md="6"><v-text-field v-model.number="tahsilatekle.tutar"   :class="tahsilatekle.tutar =='' ? 'text-red' : ''"      :rules="[tahsilatrules.required]" type="text" variant="outlined" hide-details="auto" density="compact" label="TUTAR*" required></v-text-field></v-col>
                            <v-col cols="12" sm="6" md="6"><v-text-field v-model="tahsilatekle.tarih"   :class="tahsilatekle.tarih =='' ? 'text-red' : ''"      :rules="[tahsilatrules.required]" type="date" variant="outlined" hide-details="auto" density="compact" label="TARİH*" required></v-text-field></v-col>
                            <v-col cols="12" sm="6" md="6"><v-select v-model="tahsilatekle.odemesekli"  :class="tahsilatekle.odemesekli =='' ? 'text-red' : ''" :rules="[tahsilatrules.required]" :items="tahsilatselect.odemesekli" hide-details="auto" density="compact" label="ÖDEME ŞEKLİ*" required></v-select></v-col>
                            <v-col cols="12" sm="6" md="6"><v-text-field v-model="tahsilatekle.not" type="text" variant="outlined" hide-details="auto" density="compact" label="NOT*" required></v-text-field></v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="submit">KAYDET</v-btn>
                    <v-btn @click="show = false">ÇIKIŞ</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "TahsilatCreate",
  props: ["visibletsup","tahsilatpropid"],
  data() {
      return {
          tahsilatekle:{
            tutar    : "",
            tarih : "",
            odemesekli : "",
            not    : "",
          }
      }
  },
  methods: {
      ...mapActions({
        tahsilatupdate:"tahsilat/tahsilatupdate",
        tahsilatreadone:"tahsilat/tahsilatreadone"
      }),
      async updatea() {
        this.tahsilatekle = await this.tahsilatreadone({id:this.tahsilatpropid})
      },
      async submit() {
        if(await this.$refs.form.validate().then(r=>r.valid)) {
            delete this.tahsilatekle.personelId
            delete this.tahsilatekle.createdAt
            delete this.tahsilatekle.updatedAt
            this.tahsilatupdate(this.tahsilatekle);
            Object.keys(this.tahsilatekle).forEach(r=>{this.tahsilatekle[r]=""});
            this.tahsilatekle.durum="ÇALIŞIYOR";
            this.show = false;
        }
      },
  },
  computed: {
      show: {
          get () {return this.visibletsup},
          set (value) {
            if(!value) {this.$emit("close")}
            Object.keys(this.tahsilatekle).forEach(r=>{this.tahsilatekle[r]=""});
            this.tahsilatekle.durum="ÇALIŞIYOR";
          },
      },
      
      width() {
        switch(this.$vuetify.display.name) {
            case "xs":  return 300
            case "sm":  return 600
            case "md":  return 600
            case "lg":  return 600
            case "xl":  return 600
            case "xxl": return 600
            default:    return 600
        }
      },
      ...mapGetters({
          tahsilatselect: "tahsilat/getTahsilatselect",
          tahsilatrules: "tahsilat/getTahsilatrules",
      }),
  },
  watch: {
    visibletsup: function () { if(this.visibletsup==true) this.updatea()},
    'tahsilatekle.tarih': function (newValue) {this.tahsilatekle.tarih = this.$filters.dateymd(newValue)}
  }
};
</script>
