<template>
  <v-app>
    <app-header v-if="loggedIn"></app-header>
    <app-sidebar v-if="loggedIn"></app-sidebar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from './components/shared/AppHeader.vue';
import AppSidebar from './components/shared/AppSidebar.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppSidebar
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
}
</script>
