export const sekmeler = {
    namespaced: true,
    state: {
      rail:true,
      sidebar: "anasayfa",
    },
    getters: {
      getRail: state => state.rail,
      getSidebar: state => state.sidebar,
    },
    actions: {},
    mutations: {
      set_rail: (state, data) => state.rail = data,
      set_sidebar: (state, data) => state.sidebar = data,
    },
}