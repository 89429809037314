<template>
  <v-banner border density="compact" lines="one" :color="renkbanner" class="px-0 mb-2">
    <v-banner-icon icon="mdi-account" class="pa-1 ma-1 align-self-center"></v-banner-icon>
    <v-banner-text class="pr-0">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col cols="12"><slot name="fullName"></slot></v-col>
          <v-col cols="12"><slot name="borc"></slot></v-col>
        </v-row>
      </v-container>
    </v-banner-text>
    <v-banner-actions>
      <v-container fluid class="pa-0">
        <slot name="action"></slot>
      </v-container>
    </v-banner-actions>
  </v-banner>
</template>

<script>
export default {
  name: "MaasBorcindexbanner",
  props:["renkbanner"],
}
</script>