export const roledirective = {
  namespaced: true,
  state: {
    roledirectivedata:""
  },
  getters: {
    getRoledirectivedata: state => state.roledirectivedata,
  },
  actions: {},
  mutations: {
    set_roledirectivedata: state => state.roledirectivedata = JSON.parse(localStorage.getItem('user')).role.name,
  }
};