import authHeader from "@/service/authHeader";
import http from "@/plugins/http";

class PersoneldetailService {
  personeldetailread() {
    return http.get("/v1/personeldetail", { headers: authHeader() });
  }
  personeldetailreadone(data) {
    return http.get(`/v1/personeldetail/one`, { headers: authHeader(), params: data});
  }
}

export default new PersoneldetailService();
