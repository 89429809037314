<template>
  <PersonelView />
</template>

<script>
import PersonelView from "@/components/forms/personel/PersonelView";

export default {
  name: "MaasPersonelView",
  components: {
    PersonelView,
  },
};
</script>