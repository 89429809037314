import TahsilatService from "@/service/TahsilatService";

export const tahsilat = {
    namespaced: true,
    state: {
        tahsilatdata: [],
        tahsilatselect:{
            odemesekli:["ELDEN","BANKA İŞBANK","BANKA ZİRAAT"],
        },
        tahsilatrules:{
            required: v=> !!v || "Gerekli",
        }
    },
    getters: {
        getTahsilatdata: state=> state.tahsilatdata,
        getTahsilatselect: state=> state.tahsilatselect,
        getTahsilatrules: state=> state.tahsilatrules,
        getTahsilatdatadetail: state => id => state.tahsilatdata.filter(v=>v.personelId == id),
    },
    actions: {
        tahsilatcreate({ commit, dispatch }, datas) {
            TahsilatService.tahsilatcreate(datas).then(res => {
                commit("set_tahsilatdatapush", res.data);
                dispatch("personeldetail/personeldetailread", {}, {root:true});
            }).catch(e => console.log(e.response.data));
        },
        tahsilatread({ commit, dispatch }) {
            TahsilatService.tahsilatread().then(res => {
                commit("set_tahsilatdataread", res.data);
                dispatch("personeldetail/personeldetailread", {}, {root:true});
            }).catch(e => console.log(e.response.data))
        },
        tahsilatupdate({ dispatch }, datas) {
            TahsilatService.tahsilatupdate(datas).then(() => {
                dispatch("tahsilatread");
            }).catch(e => console.log(e.response.data))
        },
        tahsilatdeleted({ dispatch }, datas) {
            TahsilatService.tahsilatdeleted(datas).then(() => {
                dispatch("tahsilatread");
            }).catch(e => console.log(e.response.data))
        },
        tahsilatreadone(context, datas) {
            return TahsilatService.tahsilatreadone(datas).then((res) => res.data).catch(e => console.log(e.response.data));
        }
    },
    mutations: {
        set_tahsilatdataread: (state, data) => state.tahsilatdata = data,
        set_tahsilatdatapush: (state, data) => state.tahsilatdata.push(data),
    },
}