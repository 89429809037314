<template>
  <AdminView v-if="getRoledirectivedata == 'admin'" />
  <SupermoderatorView v-if="getRoledirectivedata == 'supermoderator'" />
  <ModeratorView v-if="getRoledirectivedata == 'moderator'" />
  <ManagerView v-if="getRoledirectivedata == 'manager'" />
  <UserView v-if="getRoledirectivedata == 'user'" />
  
  <!-- <v-container fluid>
    <v-row>
      <v-col cols="6" md="6">{{ $vuetify.display.width }}</v-col>
      <v-col cols="6" md="6">{{ $vuetify.display.name }}</v-col>
      <v-col cols="6" md="6"> TOPLAM BORÇ 100.000TL </v-col>
      <v-col cols="6" md="6"> TOPLAM ÇALIŞAN: 50 </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="9">
        
        <v-card>
          <v-tabs v-model="tab" center-active>
            <v-tab value="1">BORÇ</v-tab>
            <v-tab value="2">HEPSİ</v-tab>
            <v-tab value="3">PERSONEL</v-tab>
            <v-tab value="4">TAHAKKUK</v-tab>
            <v-tab value="5">TAHSİLAT</v-tab>
          </v-tabs>
        </v-card>
        
      </v-col>
      <v-col class="d-none d-md-flex" md="3">
        burada akış başlığı
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="9">
        <v-window v-model="tab">
          <v-window-item value="1">
            BURAYA BORÇ COMPONENTİ GELİCEK
          </v-window-item>
          <v-window-item value="2">
            BURAYA HEPSİ COMPONENTİ GELİCEK
          </v-window-item>
          <v-window-item value="3">
          </v-window-item>
          <v-window-item value="4">
            BURAYA TAHAKKUK COMPONENTİ GELİCEK
          </v-window-item>
          <v-window-item value="5">
            BURAYA TAHSİLAT COMPONENTİ GELİCEK
          </v-window-item>
        </v-window>
      </v-col>
      <v-col cols="12" md="3"> BURADA AKIŞ </v-col>
    </v-row>
  </v-container> -->
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import UserView from "@/components/rolefolders/user/UserView";
import AdminView from "@/components/rolefolders/admin/AdminView";
import SupermoderatorView from "@/components/rolefolders/supermoderator/SupermoderatorView";
import ModeratorView from "@/components/rolefolders/moderator/ModeratorView";
import ManagerView from "@/components/rolefolders/manager/ManagerView";

export default {
  name: "AnasayfaView",
  components: {
    UserView,
    AdminView,
    SupermoderatorView,
    ModeratorView,
    ManagerView,
  },
  data() {
    return {
      tab: "1",
    };
  },
  methods: {
    ...mapMutations({
      roledirectivedata: "roledirective/set_roledirectivedata",
    }),
    ...mapActions({
      personelread:"personel/personelread",
      tahakkukread:"tahakkuk/tahakkukread",
      tahsilatread:"tahsilat/tahsilatread",
    })
  },
  computed: {
    ...mapGetters({
      getRoledirectivedata: "roledirective/getRoledirectivedata",
    }),
  },
  created() {
    this.roledirectivedata();
    this.personelread();
    this.tahakkukread();
    this.tahsilatread();
  },
};
</script>
