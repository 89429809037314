import PersoneldetailService from "@/service/PersoneldetailService";

export const personeldetail = {
  namespaced: true,
  state: {
    personeldetaildata:[],
  },
  getters: {
    getPersoneldetaildata: state => state.personeldetaildata.map(v=>({...v,borctutar:(v.tahakkuks.map(vtah=>vtah.tutar).reduce((a,b)=>a+b,0) - v.tahsilats.map(vth=>vth.tutar).reduce((a,b)=>a+b,0))})),
    getPersoneldetaildatafiltre: state => id => state.personeldetaildata.map(v=>({
      ...v,
      borctutar:(v.tahakkuks.map(vtah=>vtah.tutar).reduce((a,b)=>a+b,0) - v.tahsilats.map(vth=>vth.tutar).reduce((a,b)=>a+b,0)),
      tahakkuktutar:v.tahakkuks.map(vtah=>vtah.tutar).reduce((a,b)=>a+b,0),
      tahsilattutar:v.tahsilats.map(vth=>vth.tutar).reduce((a,b)=>a+b,0),
    })).filter(vf=>vf.birim==id),
    getPersoneldetaildatafiltresifiryok: (_,getters) => id => getters.getPersoneldetaildatafiltre(id).filter(v=>v.borctutar !== 0),
    getPersoneldetaildatatoplam: state => state.personeldetaildata.map(v=>(v.tahakkuks.map(vtah=>vtah.tutar).reduce((a,b)=>a+b,0) - v.tahsilats.map(vth=>vth.tutar).reduce((a,b)=>a+b,0))).reduce((a,b)=>a+b,0),
    getPersoneldetaildatatoplamfiltre: state => id => state.personeldetaildata.filter(vf=>vf.birim==id).map(v=>(v.tahakkuks.map(vtah=>vtah.tutar).reduce((a,b)=>a+b,0) - v.tahsilats.map(vth=>vth.tutar).reduce((a,b)=>a+b,0))).reduce((a,b)=>a+b,0),
    getPersoneldetaildataaciklama: state => id => state.personeldetaildata.filter(v=>v.id===id).map(vm=>({tahakkuk:vm.tahakkuks.map(vtah=>vtah.tutar).reduce((a,b)=>a+b,0), tahsilat:vm.tahsilats.map(vth=>vth.tutar).reduce((a,b)=>a+b,0) }))
  },
  actions: {
    personeldetailread({ commit }) {
      PersoneldetailService.personeldetailread().then(res => {
        commit("set_personeldetaildataread", res.data);
      }).catch((e) => console.log(e.response.data));
    },
    personeldetailreadone(context, datas) {
      return PersoneldetailService.personeldetailreadone(datas).then((res) => res.data).catch(e => console.log(e.response.data));
    }
  },
  mutations: {
    set_personeldetaildataread : (state, data) => state.personeldetaildata = data,
  }
};