<template>
<v-row justify="center">
    <v-dialog v-model="show" :fullscreen="$vuetify.display.name=='xs'" scrollable :retain-focus="false">
        <v-form ref="form" >
            <v-card :width="width" max-height="80vh">
                <v-card-title>
                    <h2>TAHAKKUK EKLEME</h2>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container >
                        <v-row >
                            <v-col cols="12" sm="6" md="4"><v-text-field v-model.number="tahakkukekle.tutar"  :class="tahakkukekle.tutar =='' ? 'text-red' : ''"      :rules="[tahakkukrules.required]" type="text" variant="outlined" hide-details="auto" density="compact" label="TUTAR*" required></v-text-field></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select v-model.number="tahakkukekle.ay"         :class="tahakkukekle.ay =='' ? 'text-red' : ''"         :rules="[tahakkukrules.required]" :items="tahakkukselect.ay" hide-details="auto" density="compact" label="AY*" required></v-select></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select v-model.number="tahakkukekle.yil"        :class="tahakkukekle.yil =='' ? 'text-red' : ''"        :rules="[tahakkukrules.required]" :items="tahakkukselect.yil" hide-details="auto" density="compact" label="YIL*" required></v-select></v-col>
                        </v-row>
                        <v-divider class="my-4 d-none d-sm-flex"></v-divider>
                        <v-row>
                            <v-col cols="12" sm="4" md="4"><v-select v-model="tahakkukekle.cinsi"      :class="tahakkukekle.cinsi =='' ? 'text-red' : ''"      :rules="[tahakkukrules.required]" :items="tahakkukselect.cinsi" hide-details="auto" density="compact" label="CİNSİ*" required></v-select></v-col>
                            <v-col cols="12" sm="8" md="5"><v-select v-model.number="tahakkukekle.personelId" :class="tahakkukekle.personelId =='' ? 'text-red' : ''" :rules="[tahakkukrules.required]" :items="getPersonellist" hide-details="auto" density="compact" label="PERSONEL*" required></v-select></v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="submit">KAYDET</v-btn>
                    <v-btn @click="show = false">ÇIKIŞ</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "TahakkukCreate",
  props: ["visiblethcr","personelIdprop"],
  emits: ["close"],
  data() {
      return {
          tahakkukekle:{
            tutar:"",
            ay:"",
            yil:"",
            cinsi:"",
            personelId:"",
          }
      }
  },
  methods: {
      ...mapActions({
          tahakkukcreate:"tahakkuk/tahakkukcreate"
      }),
      async submit() {
        if(await this.$refs.form.validate().then(r=>r.valid)) {
            this.tahakkukcreate(this.tahakkukekle);
            Object.keys(this.tahakkukekle).forEach(r=>{this.tahakkukekle[r]=""});
            this.show = false;
        }
      },
      updatea() {
        if(this.personelIdprop) {
            this.tahakkukekle.personelId = this.personelIdprop
        }
      },
  },
  computed: {
      show: {
          get () {
              return this.visiblethcr
          },
          set (value) {
            if(!value) {this.$emit("close")}
          },
      },
      width() {
        switch(this.$vuetify.display.name) {
            case "xs":  return 300
            case "sm":  return 600
            case "md":  return 900
            case "lg":  return 900
            case "xl":  return 900
            default:    return 900
        }
      },
      ...mapGetters({
          tahakkukselect: "tahakkuk/getTahakkukselect",
          tahakkukrules: "tahakkuk/getTahakkukrules",
          getPersonellist: "personel/getPersonellist"
      })
  },
  watch: {
    visiblethcr: function () { if(this.visiblethcr==true) this.updatea()},
  }
};
</script>
