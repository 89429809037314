import http from "@/plugins/http";

class AuthService {
  login(user) {
    return http.post("/auth/signin", user)
  }

  logout() {
    localStorage.removeItem("user");
  }
}
export default new AuthService();
