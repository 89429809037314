import PermissionService from "@/service/PermissionService";

export const permission = {
  namespaced: true,
  state: {
    permissiondata:[],
  },
  getters: {
    getPermissiondata: state => state.permissiondata,
    getPermissionnamedata: state => name => state.permissiondata.find(t=>t.name === name),
  },
  actions: {
    permissioncreate({ commit }, datas) {
      PermissionService.permissioncreate(datas).then(res => {
        commit("set_permissiondatapush", res.data);
      }).catch(e => console.log(e.response.data)); 
    },
    permissionread({ commit }) {
      PermissionService.permissionread().then(res => {
        commit("set_permissiondataread", res.data);
      }).catch(e => console.log(e.response.data));
    },
    permissionupdate({ dispatch }, datas) {
      PermissionService.permissionupdate(datas).then(() => {
        dispatch("permissionread");
      }).catch(e => console.log(e.response.data)); 
    },
    permissiondeleted({ dispatch }, datas) {
      PermissionService.permissiondeleted(datas).then(() => {
        dispatch("permissionread");
      }).catch(e => console.log(e.response.data)); 
    },
  },
  mutations: {
    set_permissiondataread : (state, data) => state.permissiondata = data,
    set_permissiondatapush : (state, data) => state.permissiondata.push(data),
  }
};