import authHeader from "@/service/authHeader";
import http from "@/plugins/http";

class PermissionService {
  permissioncreate(data) {
    return http.post("/v1/permission", data, { headers: authHeader() });
  }
  permissionread() {
    return http.get("/v1/permission", { headers: authHeader() });
  }
  permissionupdate(data) {
    return http.put(`/v1/permission/${data.id}`, data, { headers: authHeader() });
  }
  permissiondeleted(data) {
    return http.delete(`/v1/permission/${data.id}`, { headers: authHeader() });
  }
}

export default new PermissionService();
