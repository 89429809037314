import authHeader from "@/service/authHeader";
import http from "@/plugins/http";

class PersonelService {
  personelcreate(data) {
    return http.post("/v1/personel", data, { headers: authHeader() });
  }
  personelread() {
    return http.get("/v1/personel", { headers: authHeader() });
  }
  personelupdate(data) {
    return http.put(`/v1/personel/${data.id}`, data, { headers: authHeader() });
  }
  personeldeleted(data) {
    return http.delete(`/v1/personel/${data}`, { headers: authHeader() });
  }
  personelreadone(data) {
    return http.get(`/v1/personel/one`, { headers: authHeader(), params: data});
  }
}

export default new PersonelService();
