<template>
  <v-row no-gutters>
    <v-col>
      <v-toolbar>
        <v-btn @click="TahakkukCreatedialog = true">EKLE</v-btn>
        <v-toolbar-title>TAHAKKUK LİSTESİ</v-toolbar-title>
      </v-toolbar>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col>
      <TahakkukRead>
        <template v-slot:readrow>
          <tr v-for="tahakkuk in tahakkuks" :key="tahakkuk.id">
            <td><v-btn @click="update(tahakkuk)" size="small"><v-icon class="d-flex d-md-none" icon="mdi-account-edit"></v-icon><span class="d-none d-md-flex">Güncelle</span></v-btn></td>
            <td><v-btn @click="deleted(tahakkuk)" size="small"><v-icon class="d-flex d-md-none" icon="mdi-delete"></v-icon><span class="d-none d-md-flex">Sil</span></v-btn></td>
            <td>{{ tahakkuk.id }}</td>
            <td>{{ tahakkuk.tutar }}</td>
            <td>{{ tahakkuk.ay }}</td>
            <td>{{ tahakkuk.yil }}</td>
            <td>{{ tahakkuk.cinsi }}</td>
            <td>{{ tahakkuk.createdAt }}</td>
            <td>{{ tahakkuk.updatedAt }}</td>
            <td>{{ tahakkuk.personelId }}</td>
            <td>{{ personellist.filter(v=>v.value==tahakkuk.personelId)[0].title }}</td>
          </tr>
        </template>
      </TahakkukRead>
    </v-col>
  </v-row>
  <TahakkukCreate  :visiblethcr="TahakkukCreatedialog" @close="TahakkukCreatedialog=false" />
  <TahakkukUpdate  :tahakkukpropid="tahakkukdata?.id" :visiblethup="TahakkukUptadedialog" @close="TahakkukUptadedialog=false" />
  <TahakkukDeleted :tahakkukprop="tahakkukdata" :visiblethde="TahakkukDeletedialog" @close="TahakkukDeletedialog=false" />
</template>

<script>
import { mapGetters} from 'vuex';
import TahakkukCreate from "@/components/forms/tahakkuk/TahakkukCreate";
import TahakkukRead from "@/components/forms/tahakkuk/TahakkukRead";
import TahakkukUpdate from "@/components/forms/tahakkuk/TahakkukUpdate";
import TahakkukDeleted from "@/components/forms/tahakkuk/TahakkukDeleted";

export default {
  name: "TahakkukView",
  components: {
    TahakkukCreate,
    TahakkukRead,
    TahakkukUpdate,
    TahakkukDeleted,
  },
  data() {
    return {
      TahakkukCreatedialog:false,
      TahakkukUptadedialog:false,
      TahakkukDeletedialog:false,
      tahakkukdata:{id:""}
    }
  },
  methods: {
    update(tahakkuk) {
      this.tahakkukdata=tahakkuk;
      this.TahakkukUptadedialog=true;
    },
    deleted(tahakkuk) {
      this.tahakkukdata=tahakkuk;
      this.TahakkukDeletedialog=true;
    },
  },
  computed: {
    ...mapGetters({
      tahakkuks:"tahakkuk/getTahakkukdata",
      personellist: "personel/getPersonellist"
    }),
  },
};
</script>