<template>
  <v-container>
    <v-row>
      <v-col>user sayfası</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UserView"
}
</script>