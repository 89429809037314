<template>
    <v-navigation-drawer expand-on-hover :permanent="!getRail" :rail="getRail">
        <v-list>
            <v-list-item
                prepend-avatar="https://randomuser.me/api/portraits/men/1.jpg"
                :title="getUser.username"
                :subtitle="getUser.email"
            ></v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list density="compact" nav>
            <v-list-item active-class="active" prepend-icon="mdi-office-building"    title="YİBF"     @click="set_sidebar('yibf')"      value="yibf"></v-list-item>
            <v-list-item active-class="active" prepend-icon="mdi-cash"               title="HAKEDİŞ"  @click="set_sidebar('hakedis')"   value="hakedis"></v-list-item>
            <v-list-item active-class="active" prepend-icon="mdi-account-hard-hat"   title="ŞANTİYE"  @click="set_sidebar('santiye')"   value="santiye"></v-list-item>
            <v-list-item active-class="active" prepend-icon="mdi-file-document"      title="TAKİP"    @click="set_sidebar('takip')"     value="takip"></v-list-item>
            <v-list-item active-class="active" prepend-icon="mdi-archive"            title="ARŞİV"    @click="set_sidebar('arsiv')"     value="arsiv"></v-list-item>
            <v-list-item active-class="active" prepend-icon="mdi-account-supervisor" title="PERSONEL" @click="set_sidebar('personel')"  value="personel"></v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name:"AppSidebar",
    methods: {
        ...mapMutations({
            set_sidebar:"sekmeler/set_sidebar"
        })
    },
    computed: {
        ...mapGetters({
            getUser: "auth/getUser",
            getRail: "sekmeler/getRail"
        })
    }
}
</script>