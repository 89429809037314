import router from "@/router/index";
import AuthService from '../../service/authService';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    getUser: state => state.user
  },
  actions: {
    login( { commit, dispatch },  user) {
      return AuthService.login(user).then(res => {
        if(res.data.access_token) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
        commit('loginSuccess', res.data);
        setTimeout(() => { dispatch("logout") }, JSON.parse(localStorage.getItem("user")).expires_in );
      }).catch((e) => { 
        commit('loginFailure');
        return Promise.reject(e)
      });
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
      router.push("/login");
    },

    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};