import { createRouter, createWebHashHistory } from 'vue-router'
import AnasayfaView from "@/views/AnasayfaView";
import LoginView from "@/views/LoginView";
const ProfilView = () => import("@/views/ProfilView");

const routes = [
  {
    path: "/",
    name: "anasayfa",
    component: AnasayfaView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/profil",
    name: "profil",
    component: ProfilView,
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
