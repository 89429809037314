<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="9">
        <v-toolbar density="compact">
          <v-toolbar-title>TOPLAM BORÇ {{ getPersoneldetaildatatoplam }} TL</v-toolbar-title>
          <v-toolbar-title>TOPLU TAHAKKUK OLUŞTUR</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
    </v-row>
    <!-- {{ getPersoneldetaildatas }} -->
    <v-row no-gutters>
      <v-col cols="12" lg="9">
        <v-row no-gutters>
          <v-col cols="12" lg="4" class="pa-1" >
            <v-toolbar class="mb-2" density="compact">
              <v-toolbar-title>OFİS TOPLAM: {{ getPersoneldetaildatatoplamfiltre("OFİS") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasListeindexbanner v-for="getPersoneldetaildatafiltre in getPersoneldetaildatafiltres('OFİS')" :key="getPersoneldetaildatafiltre.id">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltre.isim +" "+ getPersoneldetaildatafiltre.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltre.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasListeindexBilgidialog = true, getPers=getPersoneldetaildatafiltre">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltre.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasListeindexbanner>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-toolbar class="mb-2" density="compact">
              <v-toolbar-title>KİRA TOPLAM: {{ getPersoneldetaildatatoplamfiltre("KİRA") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasListeindexbanner v-for="getPersoneldetaildatafiltre in getPersoneldetaildatafiltres('KİRA')" :key="getPersoneldetaildatafiltre.id">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltre.isim +" "+ getPersoneldetaildatafiltre.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltre.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasListeindexBilgidialog = true, getPers=getPersoneldetaildatafiltre">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltre.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasListeindexbanner>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" lg="4" class="pa-1">
            <v-toolbar density="compact">
              <v-toolbar-title>KUZEY TOPLAM: {{ getPersoneldetaildatatoplamfiltre("KUZEY") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasListeindexbanner v-for="getPersoneldetaildatafiltre in getPersoneldetaildatafiltres('KUZEY')" :key="getPersoneldetaildatafiltre.id">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltre.isim +" "+ getPersoneldetaildatafiltre.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltre.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasListeindexBilgidialog = true, getPers=getPersoneldetaildatafiltre">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltre.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasListeindexbanner>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-toolbar density="compact">
              <v-toolbar-title>GÜNEY TOPLAM: {{ getPersoneldetaildatatoplamfiltre("GÜNEY") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasListeindexbanner v-for="getPersoneldetaildatafiltre in getPersoneldetaildatafiltres('GÜNEY')" :key="getPersoneldetaildatafiltre.id">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltre.isim +" "+ getPersoneldetaildatafiltre.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltre.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasListeindexBilgidialog = true, getPers=getPersoneldetaildatafiltre">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltre.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasListeindexbanner>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-toolbar density="compact">
              <v-toolbar-title>İZALA TOPLAM: {{ getPersoneldetaildatatoplamfiltre("İZALA") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasListeindexbanner v-for="getPersoneldetaildatafiltre in getPersoneldetaildatafiltres('İZALA')" :key="getPersoneldetaildatafiltre.id">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltre.isim +" "+ getPersoneldetaildatafiltre.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltre.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasListeindexBilgidialog = true, getPers=getPersoneldetaildatafiltre">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltre.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasListeindexbanner>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="3">
        <div class="bg-primary" style="height: 200px">AKIŞ</div>
      </v-col>
    </v-row>
  </v-container>
  <TahsilatCreate :personelIdprop="dataodeid" :visibletscr="TahsilatCreatedialog" @close="TahsilatCreatedialog=false" />
  <MaasListeindexBilgi :personelprop="getPers" :visiblea="MaasListeindexBilgidialog" @close="MaasListeindexBilgidialog=false" />
</template>

<script>
import { mapGetters } from "vuex";
import MaasListeindexbanner from "./listeindex/MaasListeindexbanner";
import TahsilatCreate from "@/components/forms/tahsilat/TahsilatCreate";
import MaasListeindexBilgi from "./listeindex/MaasListeindexBilgi";

export default {
  name: "MaasBorcView",
  components: {
    MaasListeindexbanner,
    TahsilatCreate,
    MaasListeindexBilgi
  },
  data() {
    return {
      TahsilatCreatedialog:false,
      MaasListeindexBilgidialog:false,
      dataodeid: false,
      getPers: false,
    }
  },
  computed: {
    ...mapGetters({
      getPersoneldetaildatatoplam:"personeldetail/getPersoneldetaildatatoplam",
      getPersoneldetaildatatoplamfiltre:"personeldetail/getPersoneldetaildatatoplamfiltre",
      getPersoneldetaildatas:"personeldetail/getPersoneldetaildata",
      getPersoneldetaildatafiltres:"personeldetail/getPersoneldetaildatafiltre",
    })
  },
  
}
</script>