<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12" md="9">
        <v-banner border density="compact" lines="one" color="red-darken-1" class="px-0 mb-2">
          <v-banner-icon color="error" icon="mdi-account-cash" class="pa-1 ma-1 align-self-center"></v-banner-icon>
          <v-banner-text class="pr-0">TOPLAM BORÇ {{ getPersoneldetaildatatoplam }} TL</v-banner-text>
          <v-banner-actions class="align-self-center">
            <v-container fluid class="pa-0">
              <v-row no-gutters>
                <v-col>
                  <v-btn size="small" class="pa-0">TAHAKKUK</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-banner-actions>
        </v-banner>
      </v-col>
    </v-row>
    <!-- {{ getPersoneldetaildatas }} -->
    <v-row no-gutters>
      <v-col cols="12" lg="9">
        <v-row no-gutters>
          <v-col cols="12" lg="4" class="pa-1" >
            <v-toolbar class="mb-2" density="compact">
              <v-toolbar-title>OFİS TOPLAM: {{ getPersoneldetaildatatoplamfiltre("OFİS") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasBorcindexbanner v-for="getPersoneldetaildatafiltresifiryok in getPersoneldetaildatafiltresifiryoks('OFİS')" :key="getPersoneldetaildatafiltresifiryok.id" :renkbanner="'yellow-darken-1'">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltresifiryok.isim +" "+ getPersoneldetaildatafiltresifiryok.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltresifiryok.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasBorcindexBilgidialog = true, getPers=getPersoneldetaildatafiltresifiryok">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltresifiryok.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasBorcindexbanner>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-toolbar class="mb-2" density="compact">
              <v-toolbar-title>KİRA TOPLAM: {{ getPersoneldetaildatatoplamfiltre("KİRA") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasBorcindexbanner v-for="getPersoneldetaildatafiltresifiryok in getPersoneldetaildatafiltresifiryoks('KİRA')" :key="getPersoneldetaildatafiltresifiryok.id" :renkbanner="'red-darken-1'">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltresifiryok.isim +" "+ getPersoneldetaildatafiltresifiryok.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltresifiryok.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasBorcindexBilgidialog = true, getPers=getPersoneldetaildatafiltresifiryok">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltresifiryok.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasBorcindexbanner>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" lg="4" class="pa-1">
            <v-toolbar density="compact">
              <v-toolbar-title>KUZEY TOPLAM: {{ getPersoneldetaildatatoplamfiltre("KUZEY") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasBorcindexbanner v-for="getPersoneldetaildatafiltresifiryok in getPersoneldetaildatafiltresifiryoks('KUZEY')" :key="getPersoneldetaildatafiltresifiryok.id" :renkbanner="'orange-darken-1'">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltresifiryok.isim +" "+ getPersoneldetaildatafiltresifiryok.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltresifiryok.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasBorcindexBilgidialog = true, getPers=getPersoneldetaildatafiltresifiryok">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltresifiryok.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasBorcindexbanner>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-toolbar density="compact">
              <v-toolbar-title>GÜNEY TOPLAM: {{ getPersoneldetaildatatoplamfiltre("GÜNEY") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasBorcindexbanner v-for="getPersoneldetaildatafiltresifiryok in getPersoneldetaildatafiltresifiryoks('GÜNEY')" :key="getPersoneldetaildatafiltresifiryok.id" :renkbanner="'blue-darken-1'">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltresifiryok.isim +" "+ getPersoneldetaildatafiltresifiryok.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltresifiryok.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasBorcindexBilgidialog = true, getPers=getPersoneldetaildatafiltresifiryok">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltresifiryok.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasBorcindexbanner>
          </v-col>
          <v-col cols="12" lg="4" class="pa-1">
            <v-toolbar density="compact">
              <v-toolbar-title>İZALA TOPLAM: {{ getPersoneldetaildatatoplamfiltre("İZALA") }} TL</v-toolbar-title>
            </v-toolbar>
            <MaasBorcindexbanner v-for="getPersoneldetaildatafiltresifiryok in getPersoneldetaildatafiltresifiryoks('İZALA')" :key="getPersoneldetaildatafiltresifiryok.id" :renkbanner="'green-darken-1'">
              <template v-slot:fullName>{{ getPersoneldetaildatafiltresifiryok.isim +" "+ getPersoneldetaildatafiltresifiryok.soyisim }}</template>
              <template v-slot:borc>{{ getPersoneldetaildatafiltresifiryok.borctutar }} TL</template>
              <template v-slot:action>
                <v-row no-gutters><v-col><v-btn @click="MaasBorcindexBilgidialog = true, getPers=getPersoneldetaildatafiltresifiryok">BİLGİ</v-btn></v-col></v-row>
                <v-row no-gutters><v-col><v-btn @click="TahsilatCreatedialog = true, dataodeid=getPersoneldetaildatafiltresifiryok.id" >ödeme</v-btn></v-col></v-row>
              </template>
            </MaasBorcindexbanner>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="3">
        <div class="bg-primary" style="height: 200px">AKIŞ</div>
      </v-col>
    </v-row>
  </v-container>
  <TahsilatCreate :personelIdprop="dataodeid" :visibletscr="TahsilatCreatedialog" @close="TahsilatCreatedialog=false" />
  <MaasBorcindexBilgi :personelprop="getPers" :visiblea="MaasBorcindexBilgidialog" @close="MaasBorcindexBilgidialog=false" />
</template>

<script>
import { mapGetters } from "vuex";
import MaasBorcindexbanner from "./borcindex/MaasBorcindexbanner";
import TahsilatCreate from "@/components/forms/tahsilat/TahsilatCreate";
import MaasBorcindexBilgi from "./borcindex/MaasBorcindexBilgi";

export default {
  name: "MaasBorcView",
  components: {
    MaasBorcindexbanner,
    TahsilatCreate,
    MaasBorcindexBilgi
  },
  data() {
    return {
      TahsilatCreatedialog:false,
      MaasBorcindexBilgidialog:false,
      dataodeid: false,
      getPers: false,
    }
  },
  computed: {
    ...mapGetters({
      getPersoneldetaildatatoplam:"personeldetail/getPersoneldetaildatatoplam",
      getPersoneldetaildatatoplamfiltre:"personeldetail/getPersoneldetaildatatoplamfiltre",
      getPersoneldetaildatas:"personeldetail/getPersoneldetaildata",
      getPersoneldetaildatafiltresifiryoks:"personeldetail/getPersoneldetaildatafiltresifiryok",
    })
  },
  
}
</script>