import { createStore } from 'vuex'
import { auth } from "./modules/auth";
import { personel } from "./modules/personel";
import { tahakkuk } from "./modules/tahakkuk";
import { tahsilat } from "./modules/tahsilat";
import { permission } from "./modules/permission";
import { permissiondirective } from "./modules/permissiondirective";
import { roledirective } from "./modules/roledirective";
import { sekmeler } from "./modules/sekmeler";
import { personeldetail } from "./modules/personeldetail";

export default createStore({
  modules: {
    auth,
    personel,
    tahakkuk,
    tahsilat,
    permission,
    permissiondirective,
    roledirective,
    sekmeler,
    personeldetail,
  }
})
