<template>
  <v-container class="pa-0" fluid v-if="getSidebar=='anasayfa'">
    <v-row no-gutters>
      <v-col>
        <MaasBorcView />
      </v-col>
    </v-row>
  </v-container>
  <v-container class="pa-0" fluid v-if="getSidebar=='personel'">
    <v-row no-gutters>
      <v-col>
        <MaasView />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters,mapMutations } from 'vuex';
import MaasView from "@/components/tools/maas/MaasView";
import MaasBorcView from "@/components/tools/maas/borc/MaasBorcView";

export default {
  name: "AdminView",
  data() {
    return {
      asd1:false,
      asd2:false,
      asd3:false,
      asd4:false,
    }
  },
  components: {
    MaasView,
    MaasBorcView,
  },
  methods: {
    ...mapMutations({
      set_anasayfa:"sekmeler/set_anasayfa"
    })
  },
  computed: {
    ...mapGetters({
      getSidebar: "sekmeler/getSidebar"
    }),
  }
}
</script>