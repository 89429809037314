<template>
  <v-table fixed-header density="compact">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th>id</th>
        <th>tutar</th>
        <th>tarih</th>
        <th>odemesekli</th>
        <th>not</th>
        <th>createdAt</th>
        <th>updatedAt</th>
        <th>personelId</th>
        <th>personel ADI</th>
      </tr>
    </thead>
    <tbody>
      <slot name="readrow"></slot>
    </tbody>
  </v-table>
</template>

<script>
export default {
  name: "TahakkukRead",
};
</script>
