<template>
  <v-container>
    <v-row>
      <v-col>moderator sayfası</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ModeratorView"
}
</script>